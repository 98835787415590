interface ErrorMessage {
    message: string;
    label: string;
}

export const getErrorMessage = (err: any, model: string): string =>
{
    const fields = {
        id: 'Application id',
        defaultRedirect: 'Default Redirection URL',
        name: 'Name',
        logoSrc: 'Logo URL',
        hexColor: 'Application Color',
        description: 'Description',
        secret: 'Secret',
        requiresMfa: 'Requires MFA',
        ccmsOptions: 'CCMS Account',
        downloadMsalCache: 'Can get MSAL data',
        clientType: 'Type',
        encryptionType: 'Encryption type',
        encryptionKey: 'Encryption key',
    };

    let message = `Error creating ${model}`;
    if (err.response.status === 409) message = `${model} id already exists`;
    if (err.response.status === 422)
    {
        message = 'You have the next errors: \n';
        err.response.data.forEach((err: ErrorMessage) =>
        {
            message += `* ${(err.message.replace(`"${err.label}"`, Reflect.get(fields, err.label)))}\n`;
        });
    }
    return message;
};
