
import {reactive, toRefs, defineComponent} from 'vue';
import {Field, Form, ErrorMessage} from 'vee-validate';

export default defineComponent({
    name: 'IdentityAppFormComponent',
    components: {Field, Form, ErrorMessage},
    props: {
        appDetails: {
            required: true,
            type: Object
        },
        newApp: {
            required: true,
            type: Boolean
        },
        loading: {
            required: true,
            type: Boolean
        }
    },
    emits: ['handleApp', 'back'],
    setup (props, {emit})
    {
        const context = reactive({
            color: '#1CA085',
            formData: props.appDetails,
        });

        const editApplicationData = (): void =>
        {
            emit('handleApp', context.formData);
        };

        function back (): void
        {
            emit('back');
        }

        return {editApplicationData, back, ...toRefs(context)};
    }
});
