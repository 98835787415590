export interface Scope {
    id?: string;
    name: string;
}

export interface RedirectUrl {
    id?: string;
    url: string;
}

export class Application
{
    id= '';
    name= '';
    logoSrc= '';
    hexColor= 'FFFFFF';
    description= '';
    secret?= '';
    defaultRedirect= '';
    requiresMfa: boolean | null= null;
    ccmsOptions?: string;
    downloadMsalCache: boolean | null= null;
    clientType= '';
    encryptionType= '';
    encryptionKey= '';
    isOwned: boolean | null | undefined;
    redirectUrls: RedirectUrl[] = [];
}

export interface Domain
{
    name: string;
}

export interface AllowedDomains {
    id: string;
    name: string;
    createAt?: string;
    updatedAt?: string;
}

export interface ApplicationLog
{
    application_id: string;
    color: string;
    count: string;
    logo?: string;
    name: string;
}
