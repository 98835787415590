import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "container is-tp-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_identity_app_form_component = _resolveComponent("identity-app-form-component")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_identity_app_form_component, {
        newApp: true,
        loading: _ctx.loadingCreatingApp,
        onBack: _ctx.back,
        onHandleApp: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newApplication($event))),
        appDetails: _ctx.blankApp
      }, null, 8, ["loading", "onBack", "appDetails"])
    ])
  ]))
}