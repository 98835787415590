
import {toast} from 'bulma-toast';
import IdentityAppFormComponent from '@/components/admin/IdentityAppFormComponent.vue';
import {defineComponent, ref} from 'vue';
import {useRouter} from 'vue-router';
import {Application} from '@/types/Application';
import identity from '@/api/identity';
import {getErrorMessage} from '@/tools/handleFormErrors';

export default defineComponent({
    name: 'NewIdentityApp',
    components: {IdentityAppFormComponent},
    setup ()
    {
        const router = useRouter();
        const loadingCreatingApp = ref(false);
        const blankApp = new Application();
        blankApp.hexColor = '#FFFFFF';

        async function back (): Promise<void>
        {
            await router.push({name: 'AdminHome'});
        }

        async function newApplication (appData: Application): Promise<void>
        {
            try
            {
                const data: Application = {...appData};
                loadingCreatingApp.value = true;
                Object.keys(data)
                    .forEach((key: string) =>
                    {
                        const appKey = key as keyof Application;
                        if (data[appKey] === null || data[appKey] === '') Reflect.deleteProperty(data, appKey);
                    });
                if (data.hexColor) data.hexColor = data.hexColor.substring(1);
                Reflect.deleteProperty(data, 'name');
                Reflect.deleteProperty(data, 'logoSrc');
                Reflect.deleteProperty(data, 'redirectUrls');
                Reflect.deleteProperty(data, 'scopes');
                const res = (await identity.adminCreateApplication(data)).data;
                toast({
                    message: 'Application created successfully',
                    type: 'is-success',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
                await router.push({name: 'AdminIdentityAppDetails', params: {id: res.id as string}});
            }
            catch (e)
            {
                toast({
                    message: getErrorMessage(e, 'Application'),
                    type: 'is-danger',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
            }
            finally
            {
                loadingCreatingApp.value = false;
            }
        }

        return {back, newApplication, loadingCreatingApp, blankApp};
    }
});
